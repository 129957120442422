<template>
  <MosaicUiButtonsSecondaryButton
    theme="black"
    size="lg"
    :loading="isLoading"
    class="w-full"
  >
    <Icon v-if="!isLoading" name="logos:facebook" size="16" />
    {{ t(label) }}
  </MosaicUiButtonsSecondaryButton>
</template>

<script setup lang="ts">
const {t} = useI18n();

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: 'auth.methods.facebook'
  }
})
</script>

<style scoped>

</style>
